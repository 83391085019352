#login-background{
  background-image: url("file:///Users/scerfoglio/Proyectos/4%20Tech/4tech-interface-web/public/login.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 25%;
}

#login-form{
  margin-top: 150px;
}

.btn-primary{
  background-color: #49A5DF !important;
  border-color: #49A5DF !important;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
}

.btn-primary:focus{
  background-color: #0D72B5 !important;
  border-color: #0D72B5 !important;
}

.btn-primary:hover{
  background-color: #0D72B5 !important;
  border-color: #0D72B5 !important;
}

.link-primary{
  color: #49A5DF !important;
  text-decoration: none !important;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
}

.link-primary:focus{
  color: #0D72B5 !important;
}

.link-primary:hover{
  color: #0D72B5 !important;
}

.btn-info{
  background-color: #EDCD1E !important;
  border-color: #EDCD1E !important;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
}

.btn-info:focus{
  background-color: #fae570 !important;
  border-color: #fae570 !important;
}

.btn-info:hover{
  background-color: #fae570 !important;
  border-color: #fae570 !important;
}

.link-info{
  color: #EDCD1E !important;
  text-decoration: none !important;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
}

.link-info:focus{
  color: #fae570 !important;
}

.link-info:hover{
  color: #fae570 !important;
}

.navbar-menu-not-active{
  text-decoration: none;
  background-color: #EDCD1E !important;
  padding-top: 9px;
  padding-bottom: 11px;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
  color: black !important;
}

.navbar-menu-not-active:focus{
  text-decoration: "none";
  background-color: #fae570 !important;
  border-color: #fae570 !important;
  color: black !important;
}

.navbar-menu-not-active:hover{
  text-decoration: "none";
  background-color: #fae570 !important;
  border-color: #fae570 !important;
  color: black !important;
}

.navbar-menu-active{
  text-decoration: none;
  background-color: #fae570 !important;
  padding-top: 9px;
  padding-bottom: 11px;
  color: black !important;
}

.subnavbar-menu-not-active{
  text-decoration: none;
  background-color: #0D72B5 !important;
  padding-top: 9px;
  padding-bottom: 11px;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
  color: white;
}

.subnavbar-menu-not-active:focus{
  text-decoration: "none";
  background-color: #0D72B5 !important;
  border-color: #0D72B5 !important;
  color: white;
}

.subnavbar-menu-not-active:hover{
  text-decoration: "none";
  background-color: #49A5DF !important;
  border-color: #49A5DF !important;
  color: white;
}

.subnavbar-menu-active{
  text-decoration: none;
  background-color: #49A5DF !important;
  padding-top: 9px;
  padding-bottom: 11px;
  color: white;
}


.cards{
  border: none !important;
  box-shadow: 1px 3px 5px #CFCFCF !important;
}

.cards.content{
  padding: 0 !important;
}

.content-table.head{
  text-align: center;
  border-bottom: 2px solid #A0A0A0;
  background-color: #EBEBEB;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: bold;
}

.content-table.rows{
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 2px solid #EBEBEB;
}

.middle{
  text-align: center;
}

.content-table.rows.right{
  text-align: right;
}

.content-table.status{
  padding: 12px;
  font-size: 1rem;
  font-weight: 400 !important;
}

.content-options.content{
  height: 72px;
  border-bottom: 3px solid #EBEBEB !important;
}

.content-options.rows.left{
  padding-left: 32px;
  width: 50%;
}

.content-options.rows.right{
  padding-right: 32px;
  text-align: right;
}

.column-title{
  background-color: #49A5DF;
  text-align: center;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 10px 10px 0px 0px;
  color: white
}

.column-content{
  background-color: #f9f9f9;
  padding: 16px;
  height: 100%;
}

.column-content-nh{
  background-color: #FAFAFA;
  padding: 16px;
}

.fc-col-header-cell-cushion {
  text-decoration: none !important;
}

.fc-daygrid-day-number{
  text-decoration: none !important;
}

.table-bordered>:not(caption)>*>*{
  border: none !important;
}

.file.element{
  position: relative;
  /* display: flex; */
  /* align-items: center; */
  width: 100%;
  padding: 0.5rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
}



.statusCard{
  border-right: none !important;
  border-top: none !important;
  border-bottom: none !important;
  height: 130px !important;
  box-shadow: 1px 3px 5px #CFCFCF !important;
  color: black;
  text-decoration: none !important;
}

.statusCard.text{
  text-decoration: none !important;
}

.statusCard.green{
  border-left: 5px solid #28A745 !important;
}

.statusCard.yellow{
  border-left: 5px solid #FFC008 !important;
}

.statusCard.red{
  border-left: 5px solid #DC3545 !important;
}

.statusCard.blue{
  border-left: 5px solid #49A5DF !important;
}

.statusCard.administration{
  height: 108px !important;
}

.avatar-10{
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.avatar-20{
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.avatar-30{
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.avatar-40{
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.avatar-60{
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.text-error{
  background: red !important;
  color: white !important;
}

.align-center-vertically{
  display: flex;
  align-items: center;
}

.align-center-vertically-v2{
  margin-top: auto !important;
  margin-bottom: auto !important;
}

a{
  text-decoration: none !important;
}